$primary: #f95f1c;
$light-background:rgba($primary, 0.04);
$medium-background:rgba($primary, 0.30);
$neutral:#f7fafc;
$secondary: #263e4f;
$light-grey: #adadad;
$dark-background: #383838;
$shadow1:rgba($primary, 0.10) 0px 7px 29px 0px;
$shadow2:0 4px 30px rgba(0, 34, 64, 0.066);
$gradient1: linear-gradient(to top, #f7fafc 0%, #eef1f5 100%);
$yellow:#ffbb44;
$green:#7ace4c;
$red:#f33155;
$blue:#41b3f9;
$blue-01:#e7f6ff;
$green-01:#e2f6d8;
$yellow-01:#fff6e7;
$primary-01: #ffede6;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
box-sizing: border-box;
}
a{
  color: $primary;

  &:hover{
    color: #adadad;
  }
}
label{
  white-space: break-spaces;
}
.radius-1{
  border-radius: 5px;
}
.radius-2{
  border-radius: 10px;
}
.radius-3{
  border-radius: 15px;
}
.radius-4{
  border-radius: 20px;
}
.border-bottom-1{
  border: solid 1px;
  border-bottom: 1px;
}
.radius-circle{
  border-radius: 100%;
}
.opacity-0{
opacity: 0;
}
.opacity-01{
opacity: .1;
}
.opacity-02{
opacity: .2;
}
.opacity-03{
opacity: .3;
}
.opacity-04{
opacity: .4;
}
.opacity-05{
opacity: .5;
}
.opacity-07{
opacity: .7;
}
.opacity-08{
opacity: .8;
}
.opacity-09{
opacity: .9;
}
.fs-40{
  font-size: 40px;
}
.fs-35{
font-size: 35px;
}
.fs-30{
font-size: 30px;
}
.fs-25{
font-size: 25px;
}
.fs-20{
font-size: 20px;
}
.fs-18{
font-size: 18px;
}
.fs-15{
font-size: 15px;
}
.fs-12{
font-size: 12px;
}
.fs-10{
font-size: 10px;
}
.relative{
position: relative;
}
.absolute{
position: absolute;
}
.fixed{
position: fixed;
}
.top-0{
top: 0;
}
.right-0{
right: 0;
}
.bottom-0{
bottom: 0;
}
.left-10{
left: 10px;
}
.top-10{
top: 10px;
}
.right-10{
right: 10px;
}
.bottom-10{
bottom: 10px;
}
.left-15{
left: 15px;
}
.top-15{
top: 15px;
}
.right-15{
right: 15px;
}
.bottom-15{
bottom: 15px;
}
.left-20{
left: 20px;
}
.top-20{
top: 20px;
}
.right-20{
right: 20px;
}
.bottom-20{
bottom: 20px;
}
.left-20{
left: 20px;
}
.left-40{
left: 40px;
}
.top-40{
top: 40px;
}
.right-40{
right: 40px;
}
.bottom-40{
bottom: 40px;
}
.left-40{
left: 40px;
}
.hide{
display: none !important;
}
.block{
display: block !important;
}
.cursor-pointer{
cursor: pointer;
}
.cursor-grab{
cursor: grab;
}
.cursor-move{
cursor: move;
}
.flex{
display: flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.column{
  flex-direction: column;

  label{
    margin-left: 0 !important;
  }
}
.gap-5{
gap:5px;
}
.gap-10{
gap:10px;
}
.gap-15{
gap:15px;
}
.gap-20{
gap:20px;
}
.gap-35{
gap:35px;
}
.my-10{
margin-top: 10px;
margin-bottom: 10px;
}
.my-15{
margin-top: 15px;
margin-bottom: 15px;
}
.my-20{
margin-top: 20px;
margin-bottom: 20px;
}
.mr-10{
margin-right: 10px;
}
.mr-20{
margin-right: 20px;
}
.mr-30{
margin-right: 30px;
}
.mr-50{
margin-right: 50px;
}
.mt-10{
margin-top: 10px;
}
.mt-20{
margin-top: 20px;
}
.mt-30{
margin-top: 30px;
}
.mt-50{
margin-top: 50px;
}
.mt-10vh{
margin-top: 10vh;
}
.ml-10{
  margin-left: 10px;
}
.m-0{
  margin: 0;
}
.mb-0{
margin-bottom: 0px;
}
.mb-10{
margin-bottom: 10px;
}
.mb-20{
margin-bottom: 20px;
}
.mb-30{
margin-bottom: 30px;
}
.p-5{
padding: 5px;
}
.pt-5{
padding-top: 5px;
}
.pt-15{
padding-top: 15px;
}
.p-0{
  padding: 0px;
}
.pt-10{
padding-top: 10px;
}
.px-10{
padding-left: 10px;
padding-right: 10px;
}
.px-15{
padding-left: 15px;
padding-right: 15px;
}
.px-25{
padding-left: 25px;
padding-right: 25px;
}
.py-10{
padding-top: 10px;
padding-bottom: 10px;
}
.py-15{
padding-top: 15px;
padding-bottom: 15px;
}
.py-20{
padding-top: 20px;
padding-bottom: 20px;
}
.py-25{
padding-top: 25px;
padding-bottom: 25px;
}
.py-35{
padding-top: 35px;
padding-bottom: 35px;
}
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
padding: 15px;
}
.p-25{
padding: 25px;
}
.p-15{
padding: 15px;
}
.w-100{
width: 100%;
}
.w-300{
  width: 300px;
}
.vw-100{
width: 100vw;
}
.mw-sm{
max-width:300px;
}
.mw-md{
max-width:400px;
}
.mw-lg{
max-width:500px;
}
.mw-xl{
max-width:600px;
}
.mw-xxl{
max-width:700px;
}
.align-center{
align-items: center;
}
.space-between{
justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.justify-center{
  justify-content: center;
}
.min-height-unset{
  min-height: unset;
}
.h-100{
  height: 100%;
}
.vh-100{
  height: 100vh;
}
.text-sm{
  font-size: 25px;
  font-weight: 500;
}
.color-primary{
  color: $primary;
} 
.color-transparent{
  color: transparent;
} 
.color-white{
  color: #fff;
}
.color-blue{
  color: $blue;
}
.color-yellow{
  color: $yellow;
}
.color-green{
  color: $green;
}
.color-red{
  color: $red;
}
.color-black{
  color: rgb(15, 15, 15);
}
.color-secondary{
  color: $secondary;
}
.color-grey{
  color: $light-grey;
}
.hover-opacity-09:hover{
  opacity: 0.9;
}
.hover-primary:hover{
  color: $primary;
}
.hover-ml10:hover{
  transform: translateX(10px) ;
}
.hover-bg-green:hover{
  background-color: $green-01 !important;
}
.hover-bg-blue:hover{
  background-color: $blue-01 !important;
}
.hover-bg-yellow:hover{
  background-color: $yellow-01 !important;
}
.hover-bg-primary:hover{
  background-color: $primary-01 !important;
}
.fw-4{
  font-weight:400;
}
.fw-5{
  font-weight:500;
}
.fw-6{
  font-weight:600;
}
.bold{
  font-weight: bold;
}
.text-md{
  font-size: 50px;
  font-weight:700;
}
.text-xl{
  font-size: 70px;
  font-weight:700;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.bg-grey{
  background-color: #f3f3f3;
}
.bg-white{
  background-color: #fff;
}
.bg-blur{
backdrop-filter: blur(1px) brightness(0.97);
}
.bg-blur-md{
backdrop-filter: blur(3px) brightness(0.97);
}
.bg-blur-xl{
backdrop-filter: blur(5px) brightness(0.97);
}
.bg-primary{
background-color: $primary !important;
}
.bg-neutral{
background-color: $neutral !important;
}
.bg-secondary{
background-color: $secondary !important;
}
.bg-blue{
  background-color: $blue;
}
.bg-yellow{
  background-color: $yellow;
}
.bg-green{
  background-color: $green;
}
.bg-gradient1{
  background-image: $gradient1;
}
.bg-dark-01{
background-color: rgba(0, 0, 0, 0.026);
}
.ar-16-9{
  aspect-ratio: 16/9;
}
.ar-1{
  aspect-ratio: 1/1;
}
.x-scroll{
  overflow-x: scroll;
}
.overflow-hidden{
  overflow: hidden;
}
.white-space-nowrap{
  white-space: nowrap;
}
.word-break{
  word-break: break-all;
}
.hover-scale:hover{
  transform: scale(1.1);
}
.transition-01{
  transition: .1s;
}
.transition-02{
  transition: .2s;
}
//css generico
.ea-logo{
  max-width: 150px;
}
.ant-picker-datetime-panel{
  flex-wrap: wrap !important;
}
.ea-menu-button{
  padding: 20px;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  min-height: 120px;
  height: 100%;
  justify-content: center;
  font-size: 25px;
  text-align: center;

  div{
    width: 100%;
    word-break: break-word;
    white-space: break-spaces;
    line-height: 25px;
  }
}
.hide-input-label label:after{
  display: none;
}
.ant-form-item-control-input{
  min-height: unset !important;
  height: 100%;
}
.ant-modal{
  top: 10px;
}
.ant-row{
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}
.ant-layout-sider {
  position: fixed !important;
  height: calc(100vh - 40px);
  z-index: 1;
}
.ant-list-item{
  padding: 0px !important;
}
.ant-layout-sider-trigger{
  position: absolute !important;
  bottom: 15px !important;
  left: 0px;
  height: 50px;
}
.ant-typography-copy{
  color: $light-grey !important;
}
.ant-drawer-title{
  line-height: 0 !important;
}
.ant-form-item-label.ea-label {
  &:after{
      content: ":";
      position: relative;
      margin-block: 0;
      margin-inline-start: 2px;
      margin-inline-end: 8px;
  }
}
.ant-drawer-content-wrapper {
  max-width: 100%;
}
.color-preview{
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 200px;
  box-shadow: $shadow2;
}
.jodit-status-bar-link {
  display: none !important;
}
.ant-table {
  overflow-x: auto;
}
//css dashboard
.ea-header{
  max-height: 60px;
  padding: 10px;
  width: 100%;
  backdrop-filter:blur(2px);
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #f3f5f887;

  & > div{
    height: 100%;
  }
  .ea-show-drawer {
    color:$primary;
    font-size: 25px;
  }
}
.ea-logo-extended{
  height: 30px;
}
.ea-dashboard{
  background-image: $gradient1;

  .ant-layout{
    background: unset;
  }
  .ant-layout-content{
    margin-left: 200px;
    padding: 20px !important;
    gap: 10px;
  }
}
.ea-card{
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  //box-shadow: $shadow2;
}
.ea-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }
}
.ea-table-img{
  width: 60px;
  height: 60px;
  border-radius: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.page-icon{
    background-size: 50%;
  }
}
.ea-chart-wrapper{
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: 2px;  /* Firefox */

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px; 
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
  }

  .ea-chart{
    min-width: 1000px;
  }
}
.ea-sidebar{
  box-shadow: $shadow2;
}
.ea-mobile-menu{

  .ant-drawer-body, .ant-drawer-header{
    padding: 10px;
  }

}
.ea-navigation{
  border-inline-end:unset !important;

  &.primary{
    height: calc(100% - 110px);
  }
  .ea-menu-logout{
    color: $primary;
    background: #f7f8fa;
  }
}
.ea-filters-collapse{
  border: unset;
  .ant-collapse-header{
    padding: unset !important;
    .ant-collapse-header-text{
      display: none;
    }
    .ant-collapse-extra{
      width: 100%;
    }
  }
  .ant-collapse-content{
    border: unset;
    background: $neutral;
    border-radius: 10px !important;
    margin-top: 10px;
  }
  .ant-collapse-item{
    border: unset;
  }
  .ea-filters-area{
    overflow: auto;
    .ea-filter{
      min-width: 200px;
      flex-grow: 1;
    }
  }
}
.expand-content{
  margin-left: 80px !important;
}
#layoutContent{
  transition: .15s;
  transition-timing-function: ease-in-out;
}

//css apps generica
.ea-area-apps{
  .ea-app-logo{
    height: 100px;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.save-form label:after {
  display: none;
  color: transparent;
}
.color-picker{
  box-shadow: unset !important;
}

//css modifica tema
.app-preview-frame {
  max-width: 400px;
  aspect-ratio: 9/18;
  box-shadow: $shadow2;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 10px;
  min-height: 600px;

  .app-preview-navbar{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    .app-preview-logo{
      max-width: 100px;
      width: 100%;
    }
  }
  .app-preview-slider{
    max-width: 400px;
    width: 100%;
    aspect-ratio: 4/3;
  }
  .app-preview-home-grid {
    padding: 10px;
    display: grid;
    height: -webkit-fill-available;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .app-preview-home-button{
      aspect-ratio: 16/9;
      text-align: center;
      display: flex;
      font-size: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .anticon{
        margin-bottom:10px;
      }
    }
  }
}
//css pagine
.page-widget-selector{
  height: auto;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content:center;

  .widget-primary-icon {
    font-size: 24px;
    position: relative;
  }
  .widget-secodnary-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 16px;
    top: 7px;
    height: 22px;
    width: 22px;
    left: 15px;
    color: #000;
    background: white;
    padding: 2px;
    border-radius: 100%;
  }
  .widget-selector-label{
    line-height: 15px;
    text-align: center;
  }
}
.delete-user-image{
  opacity: 0;
  position: absolute;
  color: #fff;
  height: 100%;
  width: 100%;
  background: $medium-background;
  overflow: hidden;
  backdrop-filter: blur(1px);
  border-radius: 200px;
  top: 0;
  transition: .2s;

  span{
    opacity: 0;
    transform: translateY(15px);
    transition: .1s;
    transition-delay: .1s;
  }

}
.ea-form-image-wrapper{
  border-radius: 50px;
  box-shadow: $shadow1;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background-color: #fff;
  
  .ant-upload{
    border: 1px dashed transparent !important;
    transition: border-color 0.3s;
    
  }
  .ant-upload-select:hover{
      border: 1px dashed $primary !important;
  }
  .media-uploader {
    height: 100% !important;
    width: 100% !important;
    background: $light-background;
    color: $primary;

    .ant-upload{
      padding: 0 !important;
      margin: 0 !important;
      border-radius: 200px !important;
      background: unset !important;
      overflow: hidden;
      height: 100% !important;
      width: 100% !important;
    }
  }
  .delete-form-image{
    opacity: 0;
    position: absolute;
    color: #fff;
    height: 100%;
    width: 100%;
    background: $medium-background;
    overflow: hidden;
    backdrop-filter: blur(1px);
    border-radius: 200px;
    top: 0;
    transition: .2s;

    span{
      opacity: 0;
      transform: translateY(15px);
      transition: .1s;
      transition-delay: .1s;
    }

  }
  &:hover .delete-form-image{
    opacity: 1;
    span{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  &.icon{
    background-color: $primary;
    .ea-form-image{
      height: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}

//css login
.ea-login-form-area {
  border-radius: 40px 0px 0px 40px;
  height: 100vh;
  box-shadow: -20px 0px 1px 1px #f9f9f9, -20px 0px 30px rgb(0 34 64 / 7%);
  padding: 50px 20px;
}
.ea-login-image-area img{
  width: 500px;
  padding: 50px;
}



//media query
@media all and (max-width:600px) {
  .ant-picker-date-panel, .ant-picker-header, .ant-picker-body, .ant-picker-content, .ant-picker-time-panel{
    width: 100% !important;
  }
  .ant-picker-panel-layout{
    flex-wrap: wrap !important;
  }
  .ant-picker-presets{
    height: 50px;
    max-width: 100vw !important;

    & li{
      display: inline;
    }
  }
  .ea-area-apps .ea-app-logo {
    height: 70px;
  }
  .ea-card{
    padding: 15px;
  }
}
@media all and (min-width: 990px){
  .ea-sidebar{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 20px;
  }
  .mobile-hide{
    display: block;
  }
  .mobile-show{
    display: none !important;
  }
}
@media all and (max-width: 990px){
  .mobile-hide{
    display: none;
  }
  .ant-layout-content{
    margin-left: 0px !important;
  }
  .mobile-show{
    display: block;
  }
  .mobile-x-scroll{
    overflow-x: scroll;
  }
  .ea-dashboard  .ant-layout-content{
    margin-top: 70px !important;
    padding: 10px !important;
  }
  .ea-navigation.primary {
    height: calc(100% - 55px);
  }
  .ea-login-wrapper{
    justify-content: start;

    .ant-row{
      display: block;
      height: 100%;
    }
  }
  .ea-area-apps{
    margin: -10px;
  }
  .ea-login-image-area, .ea-login-form-area {
    height: auto;
  }
  .ea-login-image-area{
    height: 25%;
  }
  .ea-login-image-area img{
    width: 300px;
  }
  .ea-login-form-area {
    border-radius: 40px 40px 0px 0px !important;
    height: 75%;
    box-shadow: 0px -20px 1px 1px #f9f9f9, 0px -20px 30px rgb(0 34 64 / 7%);

    .ea-login-form-wrapper{
      justify-content: flex-start;
    }
    
  }
}
